import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getBookingsInDateRange} from '../../api/booking';
import styles from './AvailabilityCalendar.module.scss';
import Calendar from '../Calendar/Calendar.component';
import {
    eachDayOfInterval,
    endOfMonth,
    intlFormat,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    parseISO,
    startOfMonth,
} from 'date-fns';
import classNames from 'classnames';
import {Tooltip} from 'react-tooltip';
import {useTranslation} from 'react-i18next';
import {getCurrentLanguage} from '../../services/language.service';
import * as _ from 'lodash';

export const AvailabilityCalendar = ({
    item,
    quantity,
    dateRange,
    setDateRange,
    calendarError,
    setCalendarError,
}) => {
    const [bookings, setBookings] = useState([]);
    const [month, setMonth] = useState(new Date());
    const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(false);
    const [bookingDetails, setBookingDetails] = useState();
    const [today] = useState(new Date());

    const {t} = useTranslation();

    useEffect(() => {
        const fetchCalendarData = async () => {
            const firstMonthDay = startOfMonth(month);
            const lastMonthDay = endOfMonth(month);
            console.log('start and end of month ', firstMonthDay, lastMonthDay);
            const bookings = await getBookingsInDateRange(
                item.id,
                firstMonthDay,
                lastMonthDay,
            );
            setBookings(bookings);
            // const localCalendarData = Object.entries(calendarData).reduce(
            //     (acc, [date, bookings]) => {
            //         const localDate = utcToZonedTime(date, 'Europe/Riga');
            //         return {...acc, [localDate]: bookings};
            //     },
            //     {},
            // );
        };

        fetchCalendarData();
    }, [item.id, month]);

    const dateBookingMap = useMemo(() => {
        if (!bookings) return;

        return bookings.reduce((acc, booking) => {
            const eachDayOfRange = eachDayOfInterval({
                start: parseISO(booking.dateStart),
                end: parseISO(booking.dateEnd),
            });

            for (const date of eachDayOfRange) {
                acc[date] = [...(acc[date] || []), booking];
            }

            return acc;
        }, {});
    }, [bookings]);

    const dateQtyMap = useMemo(() => {
        if (!dateBookingMap) return;

        return _.chain(dateBookingMap)
            .mapValues((bookings) => {
                return bookings.reduce(
                    (sum, booking) => sum + booking.qtyWant,
                    0,
                );
            })
            .value();
    }, [dateBookingMap]);

    const datesWithBookings = useMemo(() => {
        if (!dateQtyMap) return [];
        return Object.keys(dateQtyMap).map(
            (dateString) => new Date(dateString),
        );
    }, [dateQtyMap]);

    const datesFullyBooked = useMemo(() => {
        if (!dateQtyMap) return [];

        return Object.entries(dateQtyMap).reduce((acc, [d, totalQty]) => {
            // is fully booked
            if (totalQty >= item.itemQty) {
                acc.push(new Date(d));
            }

            return acc;
        }, []);
    }, [dateQtyMap, item.itemQty]);

    const datesShortOnQuantity = useMemo(() => {
        if (!dateQtyMap) return [];

        return Object.entries(dateQtyMap).reduce(
            (acc, [d, reservedQtyOnDay]) => {
                // not fully booked but requested quantity is more than available quantity
                const itemsLeft = item.itemQty - reservedQtyOnDay;
                const date = new Date(d);
                if (
                    itemsLeft > 0 &&
                    quantity > itemsLeft &&
                    (isSameDay(date, today) || isAfter(date, today))
                ) {
                    acc.push(date);
                }

                return acc;
            },
            [],
        );
    }, [today, dateQtyMap, item.itemQty, quantity]);

    useEffect(() => {
        if (!dateRange.from || !dateRange.to) return;

        const invalid = [...datesFullyBooked, ...datesShortOnQuantity].some(
            (day) => {
                return (
                    (isAfter(day, dateRange.from) ||
                        isEqual(day, dateRange.from)) &&
                    (isBefore(day, dateRange.to) || isEqual(day, dateRange.to))
                );
            },
        );

        if (invalid) {
            setCalendarError(t('utility.calendar.error.booked'));
        } else {
            setCalendarError('');
        }
    }, [
        datesFullyBooked,
        datesShortOnQuantity,
        dateRange,
        setCalendarError,
        t,
    ]);

    console.log('dateBookingMap', dateBookingMap);
    console.log('dateQtyMap', dateQtyMap);
    console.log('datesWithBookings', datesWithBookings);
    console.log('datesFullyBooked', datesFullyBooked);
    console.log('datesShortOnQuantity', datesShortOnQuantity);

    const handleMonthChange = useCallback((month) => {
        setMonth(month);
    }, []);

    const errorHandler = useCallback(
        (error) => {
            setCalendarError(error);
        },
        [setCalendarError],
    );

    const formatDay = (date, options) => {
        const foundDay = Object.entries(dateBookingMap).find(([d2, data]) => {
            return isSameDay(new Date(d2), date);
        });
        const bookingsOnDay = foundDay?.[1]; // booking data on day
        const isFullyBooked = datesFullyBooked.find((d) => isSameDay(d, date));
        const isShortOnQuantity = datesShortOnQuantity.find((d) =>
            isSameDay(d, date),
        );

        const dateFormatted = intlFormat(new Date(date), {
            locale: options?.locale,
        });

        const hasUnapprovedBookings = bookingsOnDay?.some(
            ({status}) => status === 'approval_required',
        );

        const clickHandler = (e) => {
            if (isFullyBooked || isShortOnQuantity) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (bookingsOnDay) {
                setIsBookingDetailsOpen(true);
                setBookingDetails(
                    <div>
                        <div className={styles.bookingTooltipTitle}>
                            {dateFormatted}
                        </div>
                        {bookingsOnDay.map((booking) => {
                            const dateStart = new Date(
                                booking.dateStart,
                            ).toLocaleString(getCurrentLanguage(), {
                                day: 'numeric',
                                month: 'short',
                                timeZone: 'Europe/Riga',
                            });
                            const dateEnd = new Date(
                                booking.dateEnd,
                            ).toLocaleString(getCurrentLanguage(), {
                                day: 'numeric',
                                month: 'short',
                                timeZone: 'Europe/Riga',
                            });
                            return (
                                <div
                                    key={booking._id}
                                    className={styles.bookingDetails}>
                                    <div>
                                        {t(`tooltip.customer`)}:{' '}
                                        {booking.userID.name}{' '}
                                        {booking.userID.surname}
                                    </div>
                                    <div>
                                        {t(`tooltip.booking-dates`)}:{' '}
                                        {dateStart} - {dateEnd}
                                    </div>
                                    <div>
                                        {t(`booking-modal.quantity`)}:{' '}
                                        {booking.qtyWant}
                                    </div>
                                    {booking.comment && (
                                        <div>
                                            {t(`tooltip.comment`)}:{' '}
                                            {booking.comment}
                                        </div>
                                    )}
                                    <div>
                                        Status:{' '}
                                        {t(
                                            `booking-requests.${booking.status}`,
                                        )}{' '}
                                        {booking.status ===
                                            'approval_required' && (
                                            <span>&#10071;</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>,
                );
            } else {
                setIsBookingDetailsOpen(false);
            }
        };

        const found = dateQtyMap
            ? Object.entries(dateQtyMap).find(([d]) =>
                  isSameDay(new Date(d), date),
              )
            : undefined;
        const availableQty = item.itemQty - (found?.[1] ?? 0);

        return (
            <span
                className={classNames(styles.calendarDay, {
                    [styles.hasUnapprovedBooking]: hasUnapprovedBookings,
                    notEnoughQuantity: isShortOnQuantity,
                })}
                data-tooltip-content={`Available quantity: ${availableQty}`}
                onClick={clickHandler}>
                {date.getDate()}
            </span>
        );
    };

    if (!dateBookingMap) {
        return null;
    }

    return (
        <>
            <div id="availability-calendar">
                <Calendar
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                    disabledMatchers={{before: today}}
                    calendarError={calendarError}
                    setCalendarError={errorHandler}
                    modifiers={{
                        hasBookings: datesWithBookings,
                        fullyBooked: datesFullyBooked,
                        shortOnQuantity: datesShortOnQuantity,
                    }}
                    formatters={{formatDay}}
                    modifiersClassNames={{
                        hasBookings: styles.hasBooking,
                    }}
                    month={month}
                    onMonthChange={handleMonthChange}
                />
            </div>

            <Tooltip
                anchorSelect="#availability-calendar"
                place="left"
                isOpen={isBookingDetailsOpen}>
                {bookingDetails}
            </Tooltip>
            <Tooltip anchorSelect=".notEnoughQuantity" key={quantity} />
        </>
    );
};
