import {
    $GET_ITEM,
    $GET_POPULAR,
    $SEARCH_ITEMS,
    $UPLOAD_ITEM,
} from './item.constants';
import {getItem, getLatest, searchItems, uploadItem} from '../../api/item';

export const UPLOAD_ITEM = (dispatch) => async (props) => {
    try {
        const message = await uploadItem(props);
        dispatch({
            type: $UPLOAD_ITEM,
        });
        return message;
    } catch (err) {
        throw err;
    }
};

export const GET_POPULAR = (dispatch) => async () => {
    try {
        const response = await getLatest();
        dispatch({
            type: $GET_POPULAR,
            payload: response.data['items'],
        });
        return null;
    } catch (err) {
        return err;
    }
};

export const GET_ITEM = (dispatch) => async (id) => {
    try {
        const data = await getItem(id);
        dispatch({
            type: $GET_ITEM,
            payload: data,
        });
        return data;
    } catch (err) {
        throw err.response.data.message;
    }
};

export const SEARCH_ITEMS = (dispatch) => async (term) => {
    try {
        const data = await searchItems(term);
        dispatch({
            type: $SEARCH_ITEMS,
            payload: data,
        });
        return data;
    } catch (err) {
        throw err;
    }
};
