import styles from './Button.module.scss';
import React from 'react';
import classNames from 'classnames';

const Button = ({onClick, className, disabled, children}) => {
    return (
        <button
            className={classNames(styles.button, className)}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
