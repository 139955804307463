import React from "react";
import Input from "../../../components/Input/Input.component";
import { useState } from "react";
import validator from "validator";
import { preRegEmail } from "../../../api/auth";
import { useTranslation } from "react-i18next";
import { useNotificationHandler } from "../../../components/NotificationHandler/NotificationHandler.component";
import {useUserContext} from "../../../context/user";
import {AddressModal} from "../../../components/AddressModal/AddressModal.component";
const StepFive = ({ nextStep = () => {}, setReturnEmail = () => {} }) => {
    const { state, PATCH_USER } = useUserContext();
    const { t } = useTranslation();
    const { notification } = useNotificationHandler();
    const [ name, setName ] = useState();
    const [ surname, setSurname ] = useState();
    const [addressModal, toggleAddressModal] = useState(false);
    const [address, setAddress] = useState(state.user.address);

    const [nameError, setNameError] = useState(false);
    const [surnameError, setSurnameError] = useState(false);
    const [addressError, setAddressError] = useState(false);

    const validateFields = () => {
        setNameError(!name);
        setSurnameError(!surname);
        setAddressError(!state.user.address);

        return name && surname && state.user.address;
    };

    const sendForm = async () => {
        try {
            const validate = validateFields();
            if (validate) {
                const props = {
                    name: name,
                    surname: surname,
                    address: address,
                };
                await PATCH_USER(props);

                window.location.reload();
            } else {
                notification([t("register.please-fill-fields")], true);
            }
        } catch (err) {
            notification([err.message], true);
        }
    };

    console.log(address, addressError)

    return (
        <>
            <Input
                error={nameError && !name}
                placeholder={t("profile.name")}
                value={name}
                setValue={setName}
            ></Input>
            <Input
                error={surnameError && !surname}
                placeholder={t("profile.surname")}
                value={surname}
                setValue={setSurname}
            ></Input>
            <Input
                error={addressError && !state.user.address}
                placeholder={t("profile.address")}
                value={state.user.address}
                onClick={() => {
                    toggleAddressModal(true);
                }}
                clickable={true}
            ></Input>
            <AddressModal
                modalOpen={addressModal}
                toggleModal={toggleAddressModal}
                setAddress={setAddress}
            ></AddressModal>

            <a
                onClick={() => {
                    sendForm();
                }}
                className="register-form-field-button"
            >
                {t("register.finish")}
            </a>
        </>
    );
};
export default StepFive;
