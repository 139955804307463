import React, {useEffect, useState} from 'react';
import './bookingrequestsitem.scss';
import {imageCDNURL} from '../../api/config';
import {getExtrasPrice, getTotalPrice} from '../../services/price.service';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Modal from '../Modal/Modal.component';
import classNames from 'classnames';
import TextArea from '../TextArea/TextArea.component';
import {
    approveBooking,
    confirmPickup,
    getApprovedUser,
    refuseBooking,
} from '../../api/booking';
import {useNotificationHandler} from '../NotificationHandler/NotificationHandler.component';
import {DownIcon} from '../../assets/Icons';

const BookingRequestsItem = ({item, status}) => {
    const {t} = useTranslation();
    const options = {style: 'currency', currency: 'EUR'};
    const euroLocale = Intl.NumberFormat('lv-LV', options);
    const [isDeclining, setIsDeclining] = useState(false);
    const [declineModal, setDeclineModal] = useState(false);
    const [userDetailsModal, setUserDetailsModal] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [refusalReason, setRefusalReason] = useState('');
    const [bookingID, setBookingID] = useState('');
    const [approvedUser, setApprovedUser] = useState();
    const [price, setPrice] = useState(0);

    const [showExtras, setShowExtras] = useState(false);

    const {notification} = useNotificationHandler();

    const confirmItemPickup = async () => {
        try {
            await confirmPickup(item._id);
            window.location.reload();
        } catch (err) {
            notification([err.message], true);
        }
    };

    const approve = async (_id) => {
        try {
            await approveBooking(_id);
        } catch (err) {
            notification([err]);
        }

        window.location.reload();
    };
    const refuse = async () => {
        await refuseBooking(bookingID, refusalReason);
        window.location.reload();
    };

    useEffect(() => {
        //reset booking for refusal and reason
        if (!declineModal) {
            setBookingID('');
            setRefusalReason('');
        }
    }, [declineModal]);

    useEffect(() => {
        if (item.status == 'approved') {
            const prom = getApprovedUser(item.userID._id, item._id);
            Promise.all([prom]).then((user) => {
                setApprovedUser(user[0]);
            });
        }

        var totalPrice = getTotalPrice(
            item.itemID.rentPriceDay,
            item.itemID.rentPriceWeek,
            item.itemID.rentPriceMonth,
            item.dateStart,
            item.dateEnd,
        );
        var extrasCharge = 0;
        if (item.extras && item.extras.length) {
            extrasCharge = getExtrasPrice(item.extras);
        }

        setPrice(totalPrice * item.qtyWant + extrasCharge);
    }, []);

    return (
        <>
            <div className="booking-request-item">
                <div className="booking-request-item-container">
                    <div className="booking-request-item-image">
                        <img
                            src={`${imageCDNURL}/product_images/${item.itemID.images[0]}`}
                        />
                    </div>

                    <div className="booking-request-item-details">
                        <div className="booking-request-item-details-field">
                            <strong>{t('booking-requests.user')}: </strong>
                            <img
                                className="profile-image"
                                src={`${
                                    imageCDNURL +
                                    '/profile_images/' +
                                    item.userID.profileImage
                                }`}></img>
                            {item.userID.name} {item.userID.surname}
                        </div>
                        <div className="booking-request-item-details-field">
                            <strong>
                                {t('booking-requests.prefered-languages')}
                            </strong>
                            {item.userID.languages[0]}
                        </div>
                        <div className="booking-request-item-details-field price">
                            <strong>{t('booking-requests.price')}: </strong>
                            {euroLocale.format(price)}
                        </div>
                        <div className="booking-request-item-details-field">
                            <strong>
                                {t('booking-requests.qty-request')}:
                            </strong>
                            {item.qtyWant}
                        </div>
                    </div>

                    {item.extras && item.extras.length && (
                        <div className="booking-request-item-extras">
                            <div className="booking-request-item-extras-wrapper">
                                <div className="booking-request-item-extras-top">
                                    <strong>{t('my-bookings.extras')}</strong>
                                    <DownIcon
                                        onMouseEnter={() => {
                                            setShowExtras(true);
                                        }}
                                        onMouseLeave={() => {
                                            setShowExtras(false);
                                        }}
                                        className="booking-request-item-extras-button"></DownIcon>
                                </div>
                                <div
                                    on
                                    className="booking-request-item-extras-list"
                                    aria-expanded={showExtras}>
                                    <div className="booking-request-item-extras-list-container">
                                        {item.extras.map((value, index) => (
                                            <span key={index}>
                                                <span className="extra-title">
                                                    {value.title} dsadsa d sadsa
                                                    d as das dsa d asd as
                                                </span>
                                                <span>
                                                    {' '}
                                                    (+
                                                    {euroLocale.format(
                                                        value.price,
                                                    )}
                                                    )
                                                </span>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="booking-request-item-message">
                        <strong>{t('booking-requests.message')}:</strong>
                        <div className="booking-request-item-message-scroll">
                            <div className="booking-request-item-message-scroll-content">
                                {item.comment ? item.comment : 'No Message'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames('booking-request-item-actions')}>
                    {![
                        'with_customer',
                        'canceled',
                        'refused',
                        'returned',
                        'approved',
                    ].includes(item.status) && (
                        <>
                            <button
                                className="booking-request-item-actions-button decline"
                                onClick={() => {
                                    setDeclineModal(true);
                                    setBookingID(item._id);
                                }}
                                disabled={isApproving || isDeclining}>
                                {t('booking-requests.refuse')}
                            </button>
                            <button
                                className="booking-request-item-actions-button"
                                onClick={async () => {
                                    setIsApproving(true);
                                    await approve(item._id);
                                }}
                                disabled={isApproving || isDeclining}>
                                {isApproving
                                    ? t('booking-requests.approving')
                                    : t('booking-requests.approve')}
                            </button>
                        </>
                    )}

                    {approvedUser && (
                        <>
                            <a
                                className="booking-request-item-actions-button"
                                onClick={() => {
                                    setUserDetailsModal(true);
                                }}>
                                {t('booking-requests.view-details')}
                            </a>
                            <a
                                className="booking-request-item-actions-button"
                                onClick={confirmItemPickup}>
                                {t('booking-requests.confirm-pickup')}
                            </a>
                        </>
                    )}
                    {item.status === 'with_customer' && (
                        <Link
                            to={`qr-code/dropoff/${item._id}`}
                            className="booking-item-buttons-button">
                            {t('booking-requests.drop-off-qr')}
                        </Link>
                    )}
                </div>
            </div>
            <Modal modalOpen={declineModal} toggleModal={setDeclineModal}>
                <div className="decline-modal">
                    <h1> {t('booking-requests.refusal-reason')}</h1>
                    <TextArea
                        containerClassName="decline-modal-text"
                        placeholder={t(
                            'booking-requests.refusal-reason-optional',
                        )}
                        value={refusalReason}
                        setValue={setRefusalReason}></TextArea>
                    <div
                        className={classNames(
                            'decline-modal-button',
                            isDeclining && 'disabled',
                        )}
                        onClick={async () => {
                            setIsDeclining(true);
                            await refuse();
                        }}>
                        {isDeclining
                            ? t('booking-requests.refusing')
                            : t('booking-requests.refuse')}
                    </div>
                </div>
            </Modal>
            {approvedUser && (
                <Modal
                    modalOpen={userDetailsModal}
                    toggleModal={setUserDetailsModal}>
                    <div className="user-details-modal">
                        <h1> {t('booking-requests.user-details')}</h1>
                        <div className="user-details-modal-container">
                            <div className="item-a">
                                <strong>{t('booking-requests.email')}</strong>
                            </div>
                            <a
                                className="item-b link"
                                href={`mailto:${approvedUser['email']}`}>
                                {approvedUser['email']}
                            </a>
                            <div className="item-a">
                                <strong>{t('booking-requests.number')}</strong>
                            </div>
                            <div className="item-b">
                                +{approvedUser['phone']}
                            </div>

                            <div className="item-a">
                                <strong>
                                    {t('booking-requests.prefered-languages')}
                                </strong>
                            </div>
                            <div className="item-b">
                                {item.userID.languages[0]}
                            </div>
                            <div className="item-c">
                                <em>{t('booking-requests.language-advise')}</em>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default BookingRequestsItem;
