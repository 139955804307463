import React from "react";
import "./shimmer.scss";
const Shimmer = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer"></div>
    </div>
  );
};

export default Shimmer;
