import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'react-day-picker/dist/style.css';
import classNames from 'classnames';
import {isAfter, isBefore, isEqual, isSameDay, set} from 'date-fns';
import {useTranslation} from 'react-i18next';
import Calendar from '../Calendar/Calendar.component';

const BookingCalendar = ({
    setDateRange,
    dateRange,
    minimumSelection,
    calendarError,
    setCalendarError,
    fullyBookedDates,
    partiallyBooked,
    requestedQuantity,
    onMonthChange,
}) => {
    const shortOnQuantity = useMemo(() => {
        if (!partiallyBooked) return [];

        return partiallyBooked.filter(
            ({qtyAvailable}) => qtyAvailable < requestedQuantity,
        );
    }, [requestedQuantity, partiallyBooked]);

    const shortOnQuantityDates = useMemo(() => {
        return shortOnQuantity.map(({date}) => date);
    }, [shortOnQuantity]);

    const getViableToday = () => {
        var date = set(new Date(Date.now()), {
            hours: 12,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        });
        if (isAfter(Date.now(), date)) {
            date = new Date();
        } else {
            date = new Date(Date.now() - 24 * 60 * 60 * 1000);
        }
        return date;
    };

    const {t} = useTranslation();

    const today = getViableToday();

    useEffect(() => {
        if (!dateRange.from || !dateRange.to) return;

        const invalid = [...fullyBookedDates, ...shortOnQuantityDates].some(
            (day) => {
                return (
                    (isAfter(day, dateRange.from) ||
                        isEqual(day, dateRange.from)) &&
                    (isBefore(day, dateRange.to) || isEqual(day, dateRange.to))
                );
            },
        );

        if (invalid) {
            setCalendarError(t('utility.calendar.error.booked'));
        }
    }, [
        fullyBookedDates,
        shortOnQuantityDates,
        dateRange,
        setCalendarError,
        t,
    ]);

    const [month, setMonth] = useState(
        dateRange ? dateRange['from'] : Date.now(),
    );

    const handleMonthChange = useCallback(
        (month) => {
            setMonth(month);
            onMonthChange(month);
        },
        [onMonthChange],
    );

    const formatDay = (date, options) => {
        const isFullyBooked = fullyBookedDates?.some((d) => isSameDay(d, date));
        const isShortOnQuantityData =
            !isFullyBooked &&
            shortOnQuantity?.find((d) => isSameDay(d.date, date));
        const tooltip = isFullyBooked
            ? t('utility.calendar.not-available')
            : isShortOnQuantityData
            ? t(`utility.calendar.qty-available`, {
                  available: isShortOnQuantityData.qtyAvailable,
              })
            : undefined;
        const clickHandler = (e) => {
            if (isFullyBooked || isShortOnQuantityData) {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        return (
            <span
                className={classNames('calendar-day-container', {
                    'with-tooltip': !!tooltip,
                })}
                data-tooltip={tooltip}
                onClick={clickHandler}>
                {date.getDate()}
            </span>
        );
    };

    return (
        <Calendar
            setDateRange={setDateRange}
            dateRange={dateRange}
            disabledMatchers={{before: today}}
            calendarError={calendarError}
            setCalendarError={setCalendarError}
            modifiers={{
                today: [today],
                fullyBooked: fullyBookedDates,
                shortOnQuantity: shortOnQuantityDates,
            }}
            formatters={{formatDay}}
            month={month}
            fromMonth={today}
            onMonthChange={handleMonthChange}
            minimumSelection={minimumSelection}
        />
    );
};

export default BookingCalendar;
