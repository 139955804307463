import React, {useEffect, useState} from 'react';
import {CheckIcon, SpinnerAnimationIcon} from '../../assets/Icons';
import './checkoutpage.scss';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

export const CheckoutPage = () => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!params.has('bookingId')) {
            history.replace('/not-found');
        }

        setTimeout(() => {
            setLoading(false);
            setTimeout(() => {
                history.replace(`/bookings`);
            }, 2000);
        }, 3000);
    }, [history, params]);

    return (
        <div className="checkout-page">
            {loading ? (
                <>
                    {/*<h1>{t("checkout.processing")}</h1>*/}
                    <h1>{t('booking-requests.processing')}</h1>
                    <SpinnerAnimationIcon scale={2}></SpinnerAnimationIcon>
                    <p>{t('checkout.no-refresh')}</p>
                </>
            ) : (
                /*error ? (
        <>
          <h1>{t("checkout.wrong")}</h1>
          <CloseIcon className="error-cross"></CloseIcon>
          <p>{t("checkout.wrong2")}</p>
        </>
      ) :*/ <>
                    <h1>{t('checkout.success')}</h1>
                    <CheckIcon className="check-mark"></CheckIcon>
                    <p>{t('checkout.success2')}</p>
                </>
            )}
        </div>
    );
};
