import React, {useEffect, useState} from 'react';
import {getNews} from '../../api/news.api';
import './newsblock.scss';
import {useTranslation} from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import {imageCDNURL} from '../../api/config';
import {formatDate} from '../../services/responsive.service';
import NewsBlockSkeleton from '../../skeletons/NewsBlockSkeleton/NewsBlockSkeleton.component';

const NewsBlock = ({}) => {
    const {t} = useTranslation();
    const [news, setNews] = useState([]);

    useEffect(async () => {
        const data = await getNews(3);
        setNews(data);
    }, []);
    return news.length ? (
        <div className="news-block">
            {news.map((n) => (
                <a key={n._id} className="news-item" href={'/news/' + n._id}>
                    <div className="news-item-image">
                        <img
                            src={imageCDNURL + '/news_images/' + n.image}></img>
                    </div>
                    <div className="news-item-title">{n.title}</div>
                    <div className="news-item-text">{n.body}</div>
                    <div className="news-item-date">
                        {formatDate(new Date(n.dateAdded))}
                    </div>
                </a>
            ))}
        </div>
    ) : (
        <NewsBlockSkeleton></NewsBlockSkeleton>
    );
};

export default NewsBlock;
