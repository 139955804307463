import {imageCDNURL} from '../../api/config';
import {Link} from 'react-router-dom';
import {
    EyeClosedIcon,
    EyeOpenIcon,
    PencilIcon,
    TrashIcon,
} from '../../assets/Icons';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AvailabilityCalendar} from '../AvailabilityCalendar/AvailabilityCalendar.component';
import QuantitySelect from '../QuantitySelect/QuantitySelect.component';
import Button from '../Button/Button';
import {makeUnavailable} from '../../api/booking';
import styles from './MyShopItem.module.scss';

export const MyShopItem = ({item, onToggleVisiblity, onRemoveItem}) => {
    const {t} = useTranslation();
    const [selectedQty, setSelectedQty] = useState({value: 1, label: 1});
    const [calendarError, setCalendarError] = useState();
    const [reserveCount, setReserveCount] = useState(0);
    const [selectedRange, setSelectedRange] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const anyError =
        calendarError ||
        !selectedRange.from ||
        !selectedRange.to ||
        !selectedQty.value;

    const itemReserveHandler = async () => {
        if (!selectedRange.from || !selectedRange.to) {
            return;
        }

        setIsSubmitting(true);
        console.log('itemReserveHandler', selectedRange, selectedQty);
        await makeUnavailable({
            itemID: item.id,
            dateStart: selectedRange.from,
            dateEnd: selectedRange.to,
            qtyWant: selectedQty.value,
        });
        setReserveCount(reserveCount + 1);
        setIsSubmitting(false);
        setSelectedRange({});
    };

    return (
        <div className="my-item">
            <div className="my-item-card">
                <div>
                    <img src={imageCDNURL + '/product_images/' + item.image} />
                </div>
                <div>
                    <h3>
                        <a href={`item/${item.id}`}>{item.title}</a>
                    </h3>
                </div>
            </div>
            <div className={styles.myItemAvailability} key={reserveCount}>
                <AvailabilityCalendar
                    item={item}
                    quantity={selectedQty.value}
                    dateRange={selectedRange}
                    setDateRange={setSelectedRange}
                    calendarError={calendarError}
                    setCalendarError={setCalendarError}
                />
                <div className={styles.myItemAvailabilityActions}>
                    <QuantitySelect
                        maxQty={item.itemQty}
                        value={selectedQty}
                        onChange={setSelectedQty}
                    />
                    <Button
                        onClick={itemReserveHandler}
                        disabled={isSubmitting || anyError}>
                        {t('my-shop.make-unavailable')}
                    </Button>
                </div>
            </div>

            <div className="my-item-tools">
                <Link to={`/edit-item/${item.id}`}>
                    <PencilIcon />
                </Link>
                {item.status == 'hidden' ? (
                    <EyeClosedIcon
                        onClick={() => {
                            onToggleVisiblity(item.id);
                        }}
                    />
                ) : (
                    <EyeOpenIcon
                        onClick={() => {
                            onToggleVisiblity(item.id);
                        }}
                    />
                )}
                <TrashIcon
                    className="trash"
                    onClick={() => {
                        prompt(
                            t('my-shop.delete-prompt', {
                                item_title: item.title,
                            }),
                            t('utility.prompt.irreversible'),
                            () => {
                                onRemoveItem(item.id);
                            },
                        );
                    }}
                />
            </div>
        </div>
    );
};
