import React from 'react';
import {imageCDNURL} from '../../api/config';

const UserCard = ({user}) => {
    return (
        <a className="user-card" href={`user/${user._id}`}>
            <img
                src={
                    imageCDNURL + '/profile_images/' + user.profileImage
                }></img>
            <div className="user-card-details">
                <div className="user-card-name">{user.name}</div>
                <div className="user-card-email">{user.email}</div>
            </div>
        </a>
    );
};

export default UserCard;
