import {useEffect, useState} from 'react';

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export const formatDate = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
};

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
        isMobile: width < 1000,
    };
}

export const parseAddressSpecific = (address, type) => {
    var toReturn = '';
    address.map((entry) => {
        if (entry.types.includes(type)) {
            toReturn = entry.long_name;
        }
    });
    return toReturn;
};

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowDimensions;
}

export const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
};
