import Select from 'react-select';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const QuantitySelect = ({maxQty, menuPlacement = 'auto', value, onChange}) => {
    const {t} = useTranslation();

    const quantityOptions = useMemo(() => {
        if (!maxQty) {
            return [];
        }
        return Array(maxQty)
            .fill(1)
            .map((_, i) => ({value: i + 1, label: i + 1}));
    }, [maxQty]);

    return (
        <Select
            menuPlacement={menuPlacement}
            options={quantityOptions}
            placeholder={t('booking-modal.quantity')}
            isSearchable={false}
            value={value}
            onChange={onChange}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    height: '100%',
                    boxShadow: 'none',
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    '&:hover': {
                        borderColor: '#a3a3a3',
                    },
                    '&:before': {
                        content: `"${t('booking-modal.quantity')}"`,
                        whiteSpace: 'nowrap',
                        position: 'absolute',
                        left: '50%',
                        transform: 'translate(-50%, -27px)',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        boxShadow: 'inset 0px 8px 0px 0px white',
                        fontSize: '12px',
                        color: 'rgba(0, 0, 0, 0.7)',
                        background: !state.isDisabled && 'white',
                        fontFamily: 'Gilroy-Regular !important',
                    },
                    minWidth: '85px',
                    width: 'max-content',
                    textAlign: 'left',
                    fontFamily: 'Gilroy-Medium !important',
                }),
                singleValue: (provided, state) => ({
                    ...provided,
                    fontFamily: 'Gilroy-Medium !important',
                }),
                menu: (provided, state) => ({
                    ...provided,
                    textAlign: 'left',
                    fontFamily: 'Gilroy-Medium !important',
                }),
            }}
        />
    );
};

export default QuantitySelect;
