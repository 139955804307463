import React, {useEffect, useMemo, useRef, useState} from 'react';
import Modal from '../Modal/Modal.component';
import './bookingmodal.scss';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import Input from '../Input/Input.component';
import 'react-calendar/dist/Calendar.css';
import {getCurrentLanguage} from '../../services/language.service';
import _ from 'lodash';
import moment from 'moment';

import {
    getExtrasPrice,
    getNoDiscountPrice,
    getTotalPrice,
} from '../../services/price.service';
import {useUtilityContext} from '../../context/utility';
import {getAvailability, recordBooking} from '../../api/booking';
import {useNotificationHandler} from '../NotificationHandler/NotificationHandler.component';
import useWindowDimensions from '../../services/responsive.service';
import SlideUpMenu from '../SlideUpMenu/SlideUpMenu.component';
import {CloseIcon, QuestionIconCircle} from '../../assets/Icons';
import {useUserContext} from '../../context/user';
import BookingCalendar from '../BookingCalendar/BookingCalendar.component';
import QuantitySelect from '../QuantitySelect/QuantitySelect.component';
import Button from '../Button/Button';
import {endOfMonth, startOfMonth} from 'date-fns';

export const BookingModal = ({
    modalOpen,
    toggleModal,
    title = 'Request a booking',
    minRent,
    collateralPrice,
    rentPriceDay,
    rentPriceWeek,
    rentPriceMonth,
    itemID,
    itemQty,
    extras,
    euroLocale,
}) => {
    const {isMobile} = useWindowDimensions();
    const tcCheck = useRef();
    const {state: userState, RESET_BOOKING_INTENT} = useUserContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedCalendarMonth, setSelectedCalendarMonth] = useState(
        new Date(),
    );

    const {notification} = useNotificationHandler();

    const {state: utilityState} = useUtilityContext();

    const [qtyWant, setQtyWant] = useState({value: 1, label: 1});
    const [comment, setComment] = useState('');
    const [extrasList, setExtrasList] = useState([]);

    const [daySummary, setDaySummary] = useState('');
    const [collateralSummary, setCollateralSummary] = useState('');
    const [priceSummary, setPriceSummary] = useState('');
    const [discountSummary, setDiscountSummary] = useState('');
    const [totalSummary, setTotalSummary] = useState('');
    const [extrasSummary, setExtrasSummary] = useState('');
    const {t} = useTranslation();

    const [dateRange, setDateRange] = useState({}); //variable dates
    const [productAvailability, setProductAvailability] = useState({
        fullyBooked: undefined,
        partiallyBooked: undefined,
    });
    const [tcError, setTCError] = useState('');
    const [calendarError, setCalendarError] = useState('');
    const [extrasError, setExtrasError] = useState('');

    const isDateRangeValid = useMemo(() => {
        return dateRange.from && dateRange.to && !calendarError;
    }, [dateRange, calendarError]);
    const dayCount = useMemo(() => {
        return isDateRangeValid
            ? moment(dateRange.to).diff(dateRange.from, 'days')
            : 0;
    }, [isDateRangeValid, dateRange]);

    const redirectLink = `/checkout/`;

    const anyError = tcError || !isDateRangeValid || extrasError;

    const defaultSummary = {
        day: {range: '', total: `0 ${t('booking-modal.day-multiple')}`},
        collateral: {
            title: `${t('booking-modal.collateral')} (${euroLocale.format(
                collateralPrice,
            )}) x 0 ${t('booking-modal.item-multiple')}`,
            result: euroLocale.format(0),
        },
        price: {
            title:
                `0 ${t('booking-modal.item-multiple')} @ ` +
                euroLocale.format(0) +
                ` x 0 ${t('booking-modal.day-multiple')}`,
            result: euroLocale.format(0),
        },
        discount: '',
        extras: '',
        total: {
            title: t('booking-modal.total'),
            result: euroLocale.format(0),
        },
    };

    const producePriceSummary = () => {
        const dayString = getDateCount();
        let price = {};
        let discount = {};
        let total = {};
        // let service = {};
        let day = {};
        let extrasText = {};
        let collateral = {};

        day = {range: getDateRange(), total: dayString};
        const totalPrice = getTotalPrice(
            rentPriceDay,
            rentPriceWeek,
            rentPriceMonth,
            dateRange.from,
            dateRange.to,
        );

        const extrasPrice = getExtrasPrice(extrasList);

        const noDiscountPrice = getNoDiscountPrice(
            rentPriceDay,
            dateRange.from,
            dateRange.to,
        );
        // const serviceCharge = getServiceCharge(
        //   totalPrice,
        //   utilityState.serviceCharge,
        //   extrasPrice
        // );

        price = {
            title:
                qtyWant.value +
                ` ${t('booking-modal.item-multiple')} @ ` +
                euroLocale.format(rentPriceDay) +
                ' x ' +
                dayString,
            result: euroLocale.format(noDiscountPrice * qtyWant.value),
        };

        collateral = {
            title: `${t('booking-modal.collateral')} (${euroLocale.format(
                collateralPrice,
            )}) x ${qtyWant.value} ${t('booking-modal.item-multiple')}`,
            result: euroLocale.format(collateralPrice * qtyWant.value),
        };

        // service = {
        //   title: t("booking-modal.service-charge"),
        //   result: euroLocale.format(serviceCharge * qtyWant),
        // };

        total = {
            title: t('booking-modal.total'),
            result: euroLocale.format(
                //(totalPrice + serviceCharge) * qtyWant + extrasPrice
                totalPrice * qtyWant.value + extrasPrice,
            ),
        };

        if (extrasList.length <= 0) {
            extrasText = defaultSummary.extras;
        } else {
            extrasText = {
                title: t('booking-modal.extras'),
                result: euroLocale.format(extrasPrice),
            };
        }

        if (dayCount < 7) {
            discount = {};
        } else if (7 <= dayCount && dayCount < 30) {
            discount = {
                title: t('booking-modal.seven-discount'),
                result: euroLocale.format(
                    (totalPrice - noDiscountPrice) * qtyWant.value,
                ),
            };
        } else if (dayCount >= 30) {
            discount = {
                title: t('booking-modal.thirty-discount'),
                result: euroLocale.format(
                    (totalPrice - noDiscountPrice) * qtyWant.value,
                ),
            };
        }

        //return { day, price, service, discount, total, extrasText };
        return {day, collateral, price, discount, total, extrasText};
    };

    const getDateCount = () => {
        if (isDateRangeValid) {
            let dayString = dayCount + ' ';

            if (dayCount === 1) {
                dayString = dayString + t('booking-modal.day-single');
            } else {
                dayString = dayString + t('booking-modal.day-multiple');
            }
            return dayString;
        } else {
            return '0 Days';
        }
    };

    const getDateRange = () => {
        if (!isDateRangeValid) return;

        let dateString = dateRange.from.toLocaleString(getCurrentLanguage(), {
            day: 'numeric',
            month: 'short',
        });

        dateString =
            dateString +
            ' - ' +
            dateRange.to.toLocaleString(getCurrentLanguage(), {
                day: 'numeric',
                month: 'short',
            });

        return dateString;
    };

    const bookItem = async () => {
        const validated = validateFields();
        if (validated) {
            setIsSubmitting(true);
            setTCError('');
            const dataToSend = {
                itemID,
                comment: comment,
                dateStart: dateRange.from,
                dateEnd: dateRange.to,
                qtyWant: qtyWant.value,
                extrasList,
            };

            //defaulting the page
            setComment('');
            // setItemQty(0);
            setDateRange({});
            setQtyWant({value: 1, label: 1});
            setExtrasList([]);

            try {
                const bookingId = await recordBooking(dataToSend);

                window.location.href = `${redirectLink}?bookingId=${bookingId}`;
            } catch (err) {
                notification(
                    [err.response?.data?.message ?? err.message],
                    true,
                    100,
                );
                setIsSubmitting(false);
            }
        }
    };

    useEffect(() => {
        if (qtyWant.value && isDateRangeValid) {
            const {day, price, collateral, discount, total, extrasText} =
                producePriceSummary();

            setDaySummary(day);
            setCollateralSummary(collateral);
            setPriceSummary(price);
            // setServiceSummary(service);
            setDiscountSummary(discount);
            setTotalSummary(total);
            setExtrasSummary(extrasText);
        } else {
            setDaySummary(defaultSummary.day);
            setCollateralSummary(defaultSummary.collateral);
            setPriceSummary(defaultSummary.price);
            setDiscountSummary(defaultSummary.discount);
            // setServiceSummary(defaultSummary.service);
            setTotalSummary(defaultSummary.total);
            setExtrasSummary(defaultSummary.extras);
        }
    }, [dateRange, qtyWant, extrasList, isDateRangeValid]);

    // get product availability from today to end of month
    useEffect(() => {
        // get product availability for currently selected month in calendar component
        const getProductAvailability = async (date) => {
            setProductAvailability({
                fullyBooked: undefined,
                partiallyBooked: undefined,
            });
            const firstMonthDay = startOfMonth(date);
            const lastMonthDay = endOfMonth(date);
            const productAvailability = await getAvailability(
                itemID,
                firstMonthDay,
                lastMonthDay,
            );
            setProductAvailability({
                fullyBooked: productAvailability.fullyBooked.map(
                    ({date}) => new Date(date),
                ),
                partiallyBooked: productAvailability.partiallyBooked.map(
                    (data) => ({
                        ...data,
                        date: new Date(data.date),
                    }),
                ),
            });
        };

        if (userState.user) {
            getProductAvailability(selectedCalendarMonth);
        }
    }, [userState, selectedCalendarMonth, itemID]);

    const validateFields = () => {
        let returnBool = true;
        if (!isDateRangeValid) {
            setCalendarError(t('booking-modal.valid-dates'));
            returnBool = false;
        }

        if (!tcCheck.current.checked) {
            returnBool = false;
            setTCError(true);
        }

        if (extras && extrasList.length <= 0) {
            const allExtras = document.extras;

            for (let i = 0; i < allExtras.length; i++) {
                let val = allExtras[i];
                if (val.name == 'clearchecklist' && val.checked === false) {
                    returnBool = false;
                    setExtrasError(t('booking-modal.extras-error'));
                    return false;
                }
            }
        }
        return returnBool;
    };

    const resetTCValidation = () => {
        if (tcCheck.current.checked) {
            setTCError(false);
        } else {
            setTCError(true);
        }
    };

    const tcChecked = () => {
        resetTCValidation();
    };

    const handleExtras = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;
        const allExtras = document.extras;
        if (value == -1) {
            setExtrasList([]);
            for (let i = 0; i < allExtras.length; i++) {
                if (allExtras[i].name == 'checklist') {
                    allExtras[i].checked = false;
                }
            }
        } else {
            for (let i = 0; i < allExtras.length; i++) {
                if (allExtras[i].name == 'clearchecklist') {
                    allExtras[i].checked = false;
                }
            }
            if (isChecked) {
                setExtrasList([...extrasList, extras[value]]);
            } else {
                const filteredList = extrasList.filter(
                    (item) => !_.isEqual(item, extras[value]),
                );
                setExtrasList([...filteredList]);
            }
        }
        setExtrasError('');
    };

    const children = (
        <div>
            <div className="booking-modal-contents">
                <div className="booking-modal-contents-left">
                    <BookingCalendar
                        calendarError={calendarError}
                        setCalendarError={setCalendarError}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        fullyBookedDates={productAvailability.fullyBooked}
                        partiallyBooked={productAvailability.partiallyBooked}
                        requestedQuantity={qtyWant.value}
                        minimumSelection={minRent}
                        onMonthChange={setSelectedCalendarMonth}
                    />

                    <div className="price-summary">
                        <div className="price-summary-inner">
                            <div className="price-summary-dates">
                                {daySummary.range}
                                <div className="price-summary-dates-count">
                                    {daySummary.total}
                                </div>
                            </div>
                            <div className="price-summary-prices">
                                <div className="summary">
                                    {priceSummary && (
                                        <div className="entry">
                                            <span className="calc">
                                                {priceSummary.title}
                                            </span>
                                            <span className="result">
                                                {priceSummary.result}
                                            </span>
                                        </div>
                                    )}
                                    {extrasSummary && (
                                        <div className="entry">
                                            <span className="calc">
                                                {extrasSummary.title}
                                            </span>
                                            <span className="result">
                                                {extrasSummary.result}
                                            </span>
                                        </div>
                                    )}
                                    {/*{serviceSummary && (*/}
                                    {/*  <div className="entry">*/}
                                    {/*    <span className="calc">{serviceSummary.title}</span>*/}
                                    {/*    <span className="result">{serviceSummary.result}</span>*/}
                                    {/*  </div>*/}
                                    {/*)}*/}
                                    {discountSummary && (
                                        <div className="entry">
                                            <span className="calc">
                                                {discountSummary.title}
                                            </span>
                                            <span className="result">
                                                {discountSummary.result}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {totalSummary && (
                                    <div className="total">
                                        <span className="calc">
                                            {totalSummary.title}
                                        </span>
                                        <span className="result">
                                            {totalSummary.result}
                                        </span>
                                    </div>
                                )}

                                {collateralSummary && (
                                    <div className="summary summary-collateral">
                                        <div className="entry">
                                            <span className="calc">
                                                {collateralSummary.title}
                                            </span>
                                            <span className="result">
                                                {collateralSummary.result}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="booking-modal-contents-right">
                    {extras?.length && (
                        <div
                            className={classNames(
                                'extras',
                                !isDateRangeValid && 'disabled',
                            )}>
                            <h3>{t('booking-modal.extras')}</h3>
                            <form
                                className={classNames(
                                    'extras-content',
                                    (!isDateRangeValid || !qtyWant.value) &&
                                        'disabled',
                                    extrasError && 'error',
                                )}
                                name="extras">
                                {extras.map((value, index) => {
                                    return (
                                        <div
                                            key={value.id}
                                            className="extras-content-item">
                                            <input
                                                type="checkbox"
                                                name="checklist"
                                                disabled={!isDateRangeValid}
                                                value={index}
                                                onChange={handleExtras}></input>
                                            <label>{`${
                                                value.title
                                            } (+ ${euroLocale.format(
                                                value.price,
                                            )})`}</label>
                                            {value.description && (
                                                <div className="extras-content-tooltip-container">
                                                    <div className="extras-content-tooltip">
                                                        <QuestionIconCircle></QuestionIconCircle>
                                                    </div>
                                                    <div className="extras-content-tooltip-content">
                                                        <span>
                                                            {value.description}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                <div key={-1} className="extras-content-item">
                                    <input
                                        type="checkbox"
                                        name="clearchecklist"
                                        disabled={!isDateRangeValid}
                                        value={-1}
                                        onChange={handleExtras}></input>
                                    <label>
                                        {t('booking-modal.no-extras')}
                                    </label>
                                </div>
                            </form>
                            <div
                                className={classNames(
                                    'extras-info',
                                    extrasError && 'extras-error',
                                )}>
                                {extrasError
                                    ? extrasError
                                    : t('booking-modal.select-dates-qty')}
                            </div>
                        </div>
                    )}

                    <Input
                        placeholder={t('booking-modal.comment')}
                        className={classNames('booking-modal-input-100')}
                        value={comment}
                        setValue={setComment}
                        maxLength={200}
                    />

                    <div className="payment-options">
                        <div className="payment-options__option">
                            <input
                                type="radio"
                                id="cash"
                                name="payment"
                                value="cash"
                                defaultChecked={true}></input>
                            <label htmlFor="cash">
                                {t('booking-modal.payment.cash')}
                            </label>
                        </div>
                        <div className="payment-options__placeholder">
                            {t('booking-modal.payment.options')}
                        </div>
                    </div>

                    <div className="terms-and-conditions">
                        <input
                            type="checkbox"
                            id="terms"
                            name="terms"
                            value="accept"
                            onClick={tcChecked}
                            ref={tcCheck}></input>
                        <label
                            className={classNames(tcError && 'tc-error')}
                            htmlFor="terms">
                            {t('booking-modal.t-c')}
                        </label>
                    </div>
                    <div className="book-row">
                        <QuantitySelect
                            maxQty={itemQty}
                            menuPlacement={isMobile ? 'top' : 'auto'}
                            value={qtyWant}
                            onChange={(option) => setQtyWant(option)}
                        />
                        <Button
                            onClick={bookItem}
                            disabled={isSubmitting || anyError}>
                            {t('booking-modal.book')}
                        </Button>
                    </div>
                </div>
            </div>

            {/*{step == 1 && (*/}
            {/*  <CheckoutForm*/}
            {/*    step={step}*/}
            {/*    onGoBack={() => setStep(step - 1))}*/}
            {/*    data={dataToSend}*/}
            {/*  ></CheckoutForm>*/}
            {/*)}*/}
        </div>
    );

    return isMobile ? (
        <SlideUpMenu
            toggleMenu={toggleModal}
            menuOpen={modalOpen}
            draggable={false}>
            <div className="mobile-booking-title">
                <h1>{title}</h1>
                <CloseIcon
                    onClick={() => {
                        toggleModal(false);
                    }}></CloseIcon>
            </div>

            {children}
        </SlideUpMenu>
    ) : (
        <Modal modalOpen={modalOpen} toggleModal={toggleModal}>
            <div className="booking-modal">
                <h1>{title}</h1>
                {children}
            </div>
        </Modal>
    );
};
