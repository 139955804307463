import { useEffect } from "react";

const useUtility = ({ state, GET_CAT }) => {
  useEffect(() => {
    if (state.categories == undefined) {
      GET_CAT();
    }
  }, [state.categories]);

  // useEffect(() => {
  //   if (state.serviceCharge <= 0) {
  //     GET_SERVICE_CHARGE();
  //   }
  // }, [state.serviceCharge]);
};

export default useUtility;
