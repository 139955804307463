import React, {useMemo} from 'react';
import './bookingrequestsheader.scss';
import {getCurrentLanguage} from '../../services/language.service';
import {useTranslation} from 'react-i18next';

const BookingRequestsHeader = ({booking, status, rawStatus}) => {
    const {t} = useTranslation();
    const date = useMemo(() => {
        const dates = [new Date(booking.dateStart), new Date(booking.dateEnd)];

        return `${dates[0].toLocaleString(getCurrentLanguage(), {
            timeZone: 'Europe/Riga',
            day: 'numeric',
            month: 'short',
        })} -  ${dates[1].toLocaleString(getCurrentLanguage(), {
            day: 'numeric',
            month: 'short',
            timeZone: 'Europe/Riga',
        })}`;
    }, [booking.dateStart, booking.dateEnd]);

    return (
        <div className="booking-header">
            <div className="booking-header-title">{booking.itemID.title}</div>
            <div className="booking-header-dates">
                {t('booking-requests.dates')}: <strong>{date}</strong>
            </div>
            <div className="booking-header-status">
                {t('booking-requests.status')}:{' '}
                <strong aria-label={rawStatus}>{status}</strong>
            </div>
        </div>
    );
};

export default BookingRequestsHeader;
