import React, {useCallback, useState} from 'react';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './calendar.scss';
import classNames from 'classnames';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {enUS, lv, ru} from 'date-fns/locale';
import {getCurrentLanguage, titleCase} from '../../services/language.service';
import {format} from 'date-fns';

const Calendar = ({
    setDateRange,
    dateRange,
    minimumSelection,
    calendarError,
    setCalendarError,
    fromMonth,
    modifiers,
    modifiersClassNames = {},
    disabledMatchers,
    formatters,
    onMonthChange,
}) => {
    const locales = {en: enUS, lv: lv, ru: ru};
    const {t} = useTranslation();

    const calendarFormatters = {
        formatCaption: (month, options) => {
            return (
                <>
                    {titleCase(
                        format(month, 'LLLL', {locale: options?.locale}),
                    )}{' '}
                    {format(month, 'uuuu', {locale: options?.locale})}
                </>
            );
        },
        ...formatters,
    };

    const handleRangeClick = (date) => {
        console.log('handleRangeClick', date);
        setDateRange(date ?? {});
        if (date && date.from && date.to) {
            if (date.to - date.from == 0) {
                setCalendarError(t('utility.calendar.error.one-day'));
            } else {
                const count = moment(date.to).diff(moment(date.from), 'days');
                if (count < minimumSelection) {
                    setCalendarError(
                        t('utility.calendar.error.minimum') + minimumSelection,
                    );
                } else {
                    setCalendarError('');
                }
            }
        }
    };

    const [month, setMonth] = useState(
        dateRange ? dateRange['from'] : Date.now(),
    );

    const handleMonthChange = useCallback(
        (month) => {
            setMonth(month);
            onMonthChange(month);
        },
        [onMonthChange],
    );

    return (
        <>
            <div className={classNames('calendar', !!calendarError && 'error')}>
                <DayPicker
                    disabled={disabledMatchers}
                    modifiers={modifiers}
                    locale={locales[getCurrentLanguage()]}
                    formatters={calendarFormatters}
                    modifiersClassNames={{
                        today: 'today',
                        fullyBooked: 'fullyBooked',
                        shortOnQuantity: 'shortOnQuantity',
                        ...modifiersClassNames,
                    }}
                    month={month}
                    fromMonth={fromMonth}
                    onMonthChange={handleMonthChange}
                    className={'calendar-container'}
                    mode="range"
                    selected={dateRange}
                    onSelect={handleRangeClick}></DayPicker>
            </div>
            <div className="calendar-error">{calendarError}</div>
        </>
    );
};

export default Calendar;
