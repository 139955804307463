export const $AUTH = '$AUTH';
export const $SIGNUP = '$SIGNUP';
export const $UNAUTH = '$UNAUTH';
export const $FAIL_AUTH = '$FAIL_AUTH';
export const $SET_USER = '$SET_USER';
export const $GET_BOOKING_HISTORY = '$GET_BOOKING_HISTORY';
export const $PATCH_USER = '$PATCH_USER';
export const $PATCH_IMAGE = '$PATCH_IMAGE';
// export const $GET_USER_BALANCE = "$GET_USER_BALANCE";

export const $PATCH_ADDRESS = '$PATCH_ADDRESS';
export const $CHECK_REVIEWS = '$CHECK_REVIEWS';
