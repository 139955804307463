import {
    $AUTH,
    $CHECK_REVIEWS,
    $FAIL_AUTH,
    $GET_BOOKING_HISTORY,
    $PATCH_ADDRESS,
    $PATCH_IMAGE,
    $PATCH_USER,
    $SET_USER,
    $SIGNUP,
    $UNAUTH,
} from './user.constants';

export const UserBaseState = {
    user: false,
    token: false,
    reviewPending: undefined,
    bookingHistory: [],
    bookingHistoryLoaded: false,
    paymentMethods: false,
    // userBalance: null,
    loginAttempts: 0,
};

export const UserReducer = (state, action) => {
    const {type, payload} = action;
    switch (type) {
        case $AUTH: {
            return {
                ...state,
                token: payload,
                loginAttempts: 0,
            };
        }
        case $FAIL_AUTH: {
            return {
                ...state,
                loginAttempts: state.loginAttempts + 1,
            };
        }
        case $UNAUTH: {
            return UserBaseState;
        }
        case $SIGNUP: {
            return {
                ...state,
                token: payload,
                isRegistering: true,
            };
        }
        case $FAIL_AUTH: {
            return state;
        }
        case $SET_USER: {
            return {
                ...state,
                user: payload,
            };
        }
        case $GET_BOOKING_HISTORY: {
            return {
                ...state,
                ...payload,
            };
        }
        // case $GET_USER_BALANCE: {
        //   return {
        //     ...state,
        //     userBalance: payload,
        //   };
        // }

        case $CHECK_REVIEWS: {
            return {
                ...state,
                reviewPending: payload,
            };
        }
        case $PATCH_USER: {
            return {...state, user: payload};
        }
        case $PATCH_ADDRESS: {
            return {...state, user: {...state.user, address: payload}};
        }
        case $PATCH_IMAGE: {
            return {...state, user: {...state.user, profileImage: payload}};
        }
        default: {
            return state;
        }
    }
};
